import React from 'react';
import { VisualizationTypeT } from '../../../../../../../../types/Visualization.ts';
import { Flex, SimpleGrid } from '@chakra-ui/react';
import { VisualizationIconBox } from '../../../VisualizationIconBox';
import { NewElementHeader } from '../../../../../../../commons/components/NewElementHeader';
import { translate } from '../../../../../../../../translate';

export interface NewVisualizationValues {
	type: VisualizationTypeT;
}

interface Props {
	supportedVisualizations: VisualizationTypeT[];
	showTitle: boolean;
	onSelectedVisualization: (newVisualization: NewVisualizationValues) => void;
	onGoBack: () => void;
}

export const NewVisualization: React.FC<Props> = ({ supportedVisualizations, onSelectedVisualization, showTitle, onGoBack }) => {
	const handleSubmit = (visualizationType: VisualizationTypeT) => {
		if (visualizationType) {
			onSelectedVisualization({
				type: visualizationType,
			});
		}
	};

	return (
		<>
			{showTitle ? <NewElementHeader title={translate('What type of visualization do you want to create?')} onGoBack={onGoBack} /> : null}
			<Flex justifyContent='center' alignItems='center' textAlign='center'>
				<SimpleGrid columns={4} gap={8}>
					{supportedVisualizations.map((visualization) => (
						<VisualizationIconBox key={visualization} visualizationType={visualization} onClick={handleSubmit} />
					))}
				</SimpleGrid>
			</Flex>
		</>
	);
};
