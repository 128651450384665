import React, { useEffect } from 'react';
import { BasicCardsConfigurationT, VisualizationT } from '../../../../types/Visualization.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { useUpdateVisualization } from '../../hooks/useVisualization.ts';
import { saveBasicCardsVisualization } from '../../service/VisualizationService.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME, INPUT_DESCRIPTION_MAX_LENGHT, INPUT_TITLE_MAX_LENGHT } from '../../../../types/Constants.ts';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { VisualizationInteractionCard } from '../VisualizationInteractionCard';
import { InputTitle } from '../../../commons/components/InputTitle';
import { Box, Flex, FormControl, FormLabel, Icon, Input, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { InputSubtitle } from '../../../commons/components/InputSubtitle';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { IoSettingsSharp } from 'react-icons/io5';
import { translate } from '../../../../translate';

interface Props {
	presentationId: string;
	visualization: VisualizationT;
}

export const BasicCardsVisualization: React.FC<Props> = ({ presentationId, visualization }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);

	const updateVisualization = useUpdateVisualization(presentationId, visualization.id, (data: BasicCardsConfigurationT) =>
		saveBasicCardsVisualization(presentationId, visualization.id, data)
	);

	const conf = visualization.configuration as BasicCardsConfigurationT;

	const { register, handleSubmit, watch } = useForm<BasicCardsConfigurationT>({
		defaultValues: {
			title: visualization.title,
			description: visualization.description,
			maxColumns: conf.maxColumns,
		},
	});

	const onSubmit: SubmitHandler<BasicCardsConfigurationT> = async (data) => {
		updateVisualization.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={visualization} elementType={ElementTypeT.VISUALIZATION} />
			<VisualizationInteractionCard interaction={visualization.interaction} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<InputTitle
								title={watch('title')}
								{...register('title', {
									maxLength: INPUT_TITLE_MAX_LENGHT,
									required: true,
								})}
							/>
							<Flex flexDirection='column'>
								<InputSubtitle
									title={watch('description')}
									{...register('description', {
										maxLength: INPUT_DESCRIPTION_MAX_LENGHT,
									})}
								/>
							</Flex>
						</TabPanel>
						<TabPanel>
							<Box>
								<SlideSettingsInput presentationId={presentationId} slide={visualization.slide} />
							</Box>
							<Box my={16}>
								<FormControl>
									<Input
										type='number'
										size='lg'
										id='maxColumns'
										data-peer
										{...register('maxColumns', {
											min: 2,
											max: 6,
										})}
										min={2}
										max={6}
									/>
									<FormLabel htmlFor='maxColumns' variant='floating' size='lg'>
										{translate('Max columns')}
									</FormLabel>
								</FormControl>
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
