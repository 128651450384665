import { ThemeT } from '../model/ThemeT.ts';
import { random } from 'lodash';

export function getRandomThemeColor(theme: ThemeT): string {
	const themeColorScheme = colorShemeArray(theme.colors);
	return themeColorScheme[random(0, themeColorScheme.length)];
}

function colorShemeArray(colors: ThemeT['colors']): string[] {
	const { colorScheme } = colors;
	return [
		colorScheme.color50,
		colorScheme.color100,
		colorScheme.color200,
		colorScheme.color300,
		colorScheme.color400,
		colorScheme.color500,
		colorScheme.color600,
		colorScheme.color700,
		colorScheme.color800,
		colorScheme.color900,
	];
}
