import React, { useEffect } from 'react';
import { ThemeT } from '../../model/ThemeT.ts';
import { Alert, AlertTitle, Box, Button, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, SimpleGrid, Text } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { ColorInput } from '../../../commons/components/ColorInput';
import { INPUT_DESCRIPTION_MAX_LENGHT } from '../../../../types/Constants.ts';
import { InputTitle } from '../../../commons/components/InputTitle';
import isEmpty from 'lodash/isEmpty';
import { useUpsertTheme } from '../../hooks/useUpsertTheme.ts';
import { translate } from '../../../../translate';

interface Props {
	theme: ThemeT;
	onClose: () => void;
}

export const ThemeForm: React.FC<Props> = ({ theme, onClose }) => {
	const { isPending, isSuccess, isError, mutate } = useUpsertTheme();
	const {
		control,
		handleSubmit,
		register,
		watch,
		formState: { errors },
	} = useForm<ThemeT>({
		defaultValues: theme,
	});

	const onSubmit = (data: ThemeT) => {
		mutate(data);
	};

	useEffect(() => {
		if (isSuccess) {
			onClose();
		}
	}, [isSuccess]);

	return (
		<Modal size='full' isOpen={true} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody mx={24}>
					<Heading size='sm' textAlign='center'>
						{theme.id ? translate('Editing your theme') : translate('Creating a new theme')}
					</Heading>
					{isError ? (
						<Alert status='error'>
							<AlertTitle>Error when updating theme</AlertTitle>
						</Alert>
					) : null}
					<form id='theme-form' onSubmit={handleSubmit(onSubmit)}>
						<Box my={12}>
							<InputTitle
								title={watch('label')}
								{...register('label', {
									maxLength: INPUT_DESCRIPTION_MAX_LENGHT,
								})}
							/>
						</Box>
						<SimpleGrid columns={5} gap={8} my={4}>
							<Controller
								name={`colors.brand`}
								control={control}
								render={({ field }) => (
									<Flex w='200px' alignItems='center'>
										<ColorInput value={field.value} onChange={field.onChange} />
										<Text>Brand</Text>
									</Flex>
								)}
							/>
							<Controller
								name={`colors.textColor`}
								control={control}
								render={({ field }) => (
									<Flex w='200px' alignItems='center'>
										<ColorInput value={field.value} onChange={field.onChange} />
										<Text>Text Color</Text>
									</Flex>
								)}
							/>
							<Controller
								name={`colors.inputColor`}
								control={control}
								render={({ field }) => (
									<Flex w='200px' alignItems='center'>
										<ColorInput value={field.value} onChange={field.onChange} />
										<Text>Input Background</Text>
									</Flex>
								)}
							/>
							<Controller
								name={`colors.highlight`}
								control={control}
								render={({ field }) => (
									<Flex w='200px' alignItems='center'>
										<ColorInput value={field.value} onChange={field.onChange} />
										<Text>Highlight</Text>
									</Flex>
								)}
							/>
							<Controller
								name={`colors.background`}
								control={control}
								render={({ field }) => (
									<Flex w='200px' alignItems='center'>
										<ColorInput value={field.value} onChange={field.onChange} />
										<Text>Background Color</Text>
									</Flex>
								)}
							/>
							<Controller
								name={`colors.success`}
								control={control}
								render={({ field }) => (
									<Flex w='200px' alignItems='center'>
										<ColorInput value={field.value} onChange={field.onChange} />
										<Text>Success Color</Text>
									</Flex>
								)}
							/>
							<Controller
								name={`colors.cardBackgroundColor`}
								control={control}
								render={({ field }) => (
									<Flex w='200px' alignItems='center'>
										<ColorInput value={field.value} onChange={field.onChange} />
										<Text>Card Background</Text>
									</Flex>
								)}
							/>
							<Controller
								name={`colors.cardTextColor`}
								control={control}
								render={({ field }) => (
									<Flex w='200px' alignItems='center'>
										<ColorInput value={field.value} onChange={field.onChange} />
										<Text>Card Text</Text>
									</Flex>
								)}
							/>
							<Controller
								name={`colors.buttonColor`}
								control={control}
								render={({ field }) => (
									<Flex w='200px' alignItems='center'>
										<ColorInput value={field.value} onChange={field.onChange} />
										<Text>Button Color</Text>
									</Flex>
								)}
							/>
							<Controller
								name={`colors.buttonTextColor`}
								control={control}
								render={({ field }) => (
									<Flex w='200px' alignItems='center'>
										<ColorInput value={field.value} onChange={field.onChange} />
										<Text>Button Text</Text>
									</Flex>
								)}
							/>
						</SimpleGrid>

						<Flex mt={8} mb={4} flexDirection='column'>
							Color Scheme
						</Flex>
						<Flex>
							<Controller name={`colors.colorScheme.color50`} control={control} render={({ field }) => <ColorInput value={field.value} onChange={field.onChange} />} />
							<Controller name={`colors.colorScheme.color100`} control={control} render={({ field }) => <ColorInput value={field.value} onChange={field.onChange} />} />
							<Controller name={`colors.colorScheme.color200`} control={control} render={({ field }) => <ColorInput value={field.value} onChange={field.onChange} />} />
							<Controller name={`colors.colorScheme.color300`} control={control} render={({ field }) => <ColorInput value={field.value} onChange={field.onChange} />} />
							<Controller name={`colors.colorScheme.color400`} control={control} render={({ field }) => <ColorInput value={field.value} onChange={field.onChange} />} />
							<Controller name={`colors.colorScheme.color500`} control={control} render={({ field }) => <ColorInput value={field.value} onChange={field.onChange} />} />
							<Controller name={`colors.colorScheme.color600`} control={control} render={({ field }) => <ColorInput value={field.value} onChange={field.onChange} />} />
							<Controller name={`colors.colorScheme.color700`} control={control} render={({ field }) => <ColorInput value={field.value} onChange={field.onChange} />} />
							<Controller name={`colors.colorScheme.color800`} control={control} render={({ field }) => <ColorInput value={field.value} onChange={field.onChange} />} />
							<Controller name={`colors.colorScheme.color900`} control={control} render={({ field }) => <ColorInput value={field.value} onChange={field.onChange} />} />
						</Flex>
					</form>
					{!isEmpty(errors) ? <span>{JSON.stringify(errors)}</span> : null}
				</ModalBody>
				<ModalFooter justifyContent='center'>
					<Button variant='outline' mr={3} onClick={onClose}>
						Cancel
					</Button>
					<Button form='theme-form' type='submit' variant='submit' isLoading={isPending} disabled={isPending}>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
