import { Flex, Heading, Switch } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ExternalInteractionPicker } from '../ExternalInteractionPicker';
import { InternalInteractionPicker } from '../InternalInteractionPicker';
import { SearchInteractionResult } from '../../../../../../../interaction/service/InteractionService.ts';
import { NewElementHeader } from '../../../../../../../commons/components/NewElementHeader';
import { translate } from '../../../../../../../../translate';
import { SelectedInteractionProps } from '../ExternalInteractionPicker/ExternalInteractionPicker.tsx';

interface Props {
	presentationId: string;
	onSelectedInteraction: (interaction: SearchInteractionResult) => void;
	onGoBack: () => void;
}

export const InteractionPicker: React.FC<Props> = ({ presentationId, onSelectedInteraction, onGoBack }) => {
	const [isExternalDatasource, setIsExternalDatasource] = useState<boolean>(false);

	const handleOnSwitchChange = () => {
		setIsExternalDatasource((prev) => !prev);
	};

	const handleOnSelectedExternalInteraction = (selection: SelectedInteractionProps) => {
		onSelectedInteraction(selection.interaction);
	};

	return (
		<>
			<NewElementHeader title={translate('Using data from an existing interaction')} onGoBack={onGoBack} />
			<Flex justifyContent='center' alignItems='center'>
				<Heading size='xs' textAlign='center' mr={4} my={0}>
					{translate('Use data captured externally')}
				</Heading>
				<Switch isChecked={isExternalDatasource} onChange={handleOnSwitchChange} size='lg' />
			</Flex>
			{isExternalDatasource ? (
				<ExternalInteractionPicker currentPresentationId={presentationId} onSelectedInteraction={handleOnSelectedExternalInteraction} />
			) : (
				<InternalInteractionPicker presentationId={presentationId} onSelectedInteraction={onSelectedInteraction} />
			)}
		</>
	);
};
