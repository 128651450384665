import React from 'react';
import { ThemeT } from '../../model/ThemeT.ts';
import { ThemeForm } from '../ThemeForm';

interface Props {
	onClose: () => void;
}

const EMPTY_DEFAULT_THEME: ThemeT = {
	label: '',
	colors: {
		brand: '#000000',
		highlight: '#EDEDED',
		inputColor: '#ffffff',
		textColor: '#000000',
		background: '#ffffffde',
		success: '#748F4F',
		cardBackgroundColor: '#FFFFFF',
		cardTextColor: '#000000',
		buttonColor: '#000000',
		buttonTextColor: '#FFFFFF',
		colorScheme: {
			color50: '#BD5D58',
			color100: '#DA9A5C',
			color200: '#E2C265',
			color300: '#9C9E4C',
			color400: '#748F4F',
			color500: '#4B7F52',
			color600: '#338B81',
			color700: '#1B96B0',
			color800: '#6B97AB',
			color900: '#897A91',
		},
	},
};

export const CreateTheme: React.FC<Props> = ({ onClose }) => {
	return <ThemeForm theme={EMPTY_DEFAULT_THEME} onClose={onClose} />;
};
