import { Flex, Tag, TagLabel } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchInteractionResult } from '../../../../../../../interaction/service/InteractionService.ts';
import { useInvalidatePresentationQuery } from '../../../../../../../presentation/hooks/usePresentation.ts';
import { createVisualization } from '../../../../../../../presentation/service/ElementService.ts';
import { Loading } from '../../../../../../../commons/components/Loading';
import { NewVisualization } from '../../../NewDataVisualization/_/NewVisualization';
import { NewVisualizationValues } from '../../../NewDataVisualization/_/NewVisualization/NewVisualization.tsx';
import { NewElementHeader } from '../../../../../../../commons/components/NewElementHeader';

interface Props {
	presentationId: string;
	interaction: SearchInteractionResult;
	onGoBack: () => void;
}

export const VisualizationPicker: React.FC<Props> = ({ presentationId, interaction, onGoBack }) => {
	const invalidatePresentationQuery = useInvalidatePresentationQuery(presentationId);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const handleOnSubmit = async (newVisualization: NewVisualizationValues) => {
		setIsLoading(true);
		const newSlide = await createVisualization(presentationId, {
			title: interaction.title,
			type: newVisualization.type,
			existingInteractionId: interaction.id,
		});
		invalidatePresentationQuery();
		navigate(`/presentations/${presentationId}/slides/${newSlide.slideId}/new`);
	};

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			<NewElementHeader title='Creating a visualization for interaction' onGoBack={onGoBack} />
			<Flex justifyContent='center' mb={8}>
				<Tag size='lg' backgroundColor='colorScheme.100' textColor='textColor'>
					{/*<Image src={getIconForElement(ElementTypeT.INTERACTION)(interaction.type)} boxSize='8' alt={`${interaction.type} Interaction`} />*/}
					<TagLabel fontSize='1.5rem' textColor='textColor'>
						{interaction.title}
					</TagLabel>
				</Tag>
			</Flex>
			<Flex justifyContent='center' alignItems='center' flexDirection='column'>
				<NewVisualization supportedVisualizations={interaction.supportedVisualizations} onSelectedVisualization={handleOnSubmit} onGoBack={onGoBack} showTitle={false} />
			</Flex>
		</>
	);
};
