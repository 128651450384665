// import { ElementConfigurationT, ElementT } from "./Presentation";

import { InteractionTypeT } from './Interaction';
import { ElementSlideT } from './Slide';

export interface VisualizationT {
	id: string;
	title: string;
	description: string;
	configuration: VisualizationConfigurationT;
	type: VisualizationTypeT;
	interaction: VisualizationInteractionT;
	slide: ElementSlideT;
}

interface VisualizationInteractionT {
	id: string;
	presentationId: string;
	presentationTitle: string;
	slideId: string;
	title: string;
	description: string;
	type: InteractionTypeT;
}

export interface VisualizationConfigurationT {
	id: number;
	title: string;
	description: string;
}

export interface RotatingWordsConfigurationT extends VisualizationConfigurationT {}

export interface ComicConfigurationT extends VisualizationConfigurationT {}

export interface WordCloudConfigurationT extends VisualizationConfigurationT {
	selectedAnswers: string[];
	reference: string;
	selectedAnswersActive: boolean;
	variant: 'WORD' | 'SENTENCE';
}

export interface BarChartConfigurationT extends VisualizationConfigurationT {
	selectedAnswers: string[];
	reference: string;
	selectedAnswersActive: boolean;
}

export interface GaugeConfigurationT extends VisualizationConfigurationT {}

export interface RadarConfigurationT extends VisualizationConfigurationT {}

export interface PictogramConfigurationT extends VisualizationConfigurationT {
	icon: string;
}

export interface WheelConfigurationT extends VisualizationConfigurationT {
	chartTitle?: string;
}

export interface PieChartConfigurationT extends VisualizationConfigurationT {
	selectedAnswers: string[];
	reference: string;
	selectedAnswersActive: boolean;
	showFloatingLegend: boolean;
	showFooterLegend: boolean;
	variant: 'PIE' | 'DONUT';
}

export interface SimpleMathConfigurationT extends VisualizationConfigurationT {
	operation: 'SUM' | 'AVG';
	reference: string;
}

export interface BasicCardsConfigurationT extends VisualizationConfigurationT {
	maxColumns: number;
}

export interface BasicFlipCardsConfigurationT extends VisualizationConfigurationT {
	maxColumns: number;
}

export enum VisualizationTypeT {
	ROTATING_WORDS = 'ROTATING_WORDS',
	COMIC = 'COMIC',
	WORD_CLOUD = 'WORD_CLOUD',
	BAR_CHART = 'BAR_CHART',
	PIE_CHART = 'PIE_CHART',
	GAUGE = 'GAUGE',
	RADAR = 'RADAR',
	PICTOGRAM = 'PICTOGRAM',
	WHEEL = 'WHEEL',
	NODES = 'NODES',
	BLOCKS = 'BLOCKS',
	SIMPLE_MATH = 'SIMPLE_MATH',
	BASIC_CARDS = 'BASIC_CARDS',
	BASIC_FLIP_CARDS = 'BASIC_FLIP_CARDS',
}

export type VisualizationCompatibility = Record<VisualizationTypeT, InteractionTypeT[]>;
