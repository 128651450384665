import React, { ChangeEvent, useEffect, useState } from 'react';
import { Flex, Heading, Icon, Input, InputGroup, InputLeftElement, Text, VStack } from '@chakra-ui/react';
import { PresentationListTable } from './_/PresentationListTable';
import { CreatePresentationButton } from '../CreatePresentationButton';
import { FiSearch } from 'react-icons/fi';
import { DefaultMenu } from '../DefaultMenu';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME } from '../../../../types/Constants.ts';
import { translate } from '../../../../translate';
import { usePresentationList } from '../../hooks/usePresentationList.ts';
import { PresentationListT } from '../../../../types/Presentation.ts';
import isEmpty from 'lodash/isEmpty';
import { Loading } from '../../../commons/components/Loading';

interface Props {}

export const PresentationList: React.FC<Props> = () => {
	const [inputValue, setInputValue] = useState('');
	const [page, setPage] = useState<Date>(new Date());
	const [allData, setAllData] = useState<PresentationListT[]>([]);
	const { isLoading, data } = usePresentationList(inputValue, page);

	useEffect(() => {
		if (data) {
			//Append new page
			setAllData((prevState) => [...prevState, ...data]);
		}
	}, [data]);

	const handleOnAfterDeletion = async (deletedPresentation: PresentationListT) => {
		setAllData((prevState) => prevState.filter((p) => p.id !== deletedPresentation.id));
	};

	const handleOnInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
		setPage(new Date());
		setAllData([]);
	};

	const debouncedInputChangeHandler = debounce(handleOnInputChange, AUTO_SAVE_DEBOUNCE_TIME);

	return (
		<>
			<DefaultMenu />
			<VStack mx={16}>
				<Flex width='100%' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
					<Heading size='xs'>{translate('Your presentations')}</Heading>
					<Flex flexBasis={{ base: '100%', lg: '60%' }}>
						<Flex alignSelf='center' mb='6' width='100%'>
							<InputGroup>
								<InputLeftElement>
									<Icon as={FiSearch} />
								</InputLeftElement>
								<Input
									id='Presentation/Search'
									onChange={debouncedInputChangeHandler}
									borderBottomRadius={0}
									size='lg'
									placeholder='Search'
									_hover={{ borderColor: 'light' }}
									_focus={{ borderColor: 'light' }}
								/>
							</InputGroup>
						</Flex>
					</Flex>
					<CreatePresentationButton />
				</Flex>
				<Flex flexDirection='column' width='100%' alignItems='center' justifyContent='center'>
					{isEmpty(allData) && !isLoading ? (
						<Flex flexDirection='column' alignItems='center'>
							<Text fontSize='xl' fontWeight='bold'>
								There aren&apos;t any Presentations
							</Text>
						</Flex>
					) : null}
					<PresentationListTable presentations={allData} onNewPage={(page) => setPage(new Date(page))} onAfterDeletion={handleOnAfterDeletion} />
					{isLoading ? <Loading /> : null}
				</Flex>
			</VStack>
		</>
	);
};
