// PRESENTATION FIELDS

export const PRESENTATION_TITLE_MAX_LENGTH = 100;

//ELEMENT FIELDS
export const INPUT_DESCRIPTION_MAX_LENGHT = 1200;
export const INPUT_TITLE_MIN_LENGHT = 1;
export const INPUT_TITLE_MAX_LENGHT = 500;

//INTERACTIONS

//Multiple Choice
export const MULTIPLE_CHOICE_OPTIONS_MIN_COUNT = 1;
export const MULTIPLE_CHOICE_OPTIONS_MAX_COUNT = 20;
export const MULTIPLE_CHOICE_OPTIONS_MIN_LENGTH = 1;
export const MULTIPLE_CHOICE_OPTIONS_MAX_LENGTH = 100;

//CategorisedAnswer

export const CATEGORISED_ANSWER_MIN_COUNT_CATEGORIES = 1;
export const CATEGORISED_ANSWER_MAX_COUNT_CATEGORIES = 20;

//AUTOSAVE
export const AUTO_SAVE_DEBOUNCE_TIME = 500;

//COMPONENTS
export const TAG_SELECTOR_NAME_MAX_LENGTH = 100;

// VISUALIZATIONS

// WheelVisualization
export const WHEEL_VISUALIZATION_CHART_TITLE_MAX_CHARACTERS = 36;

// Reference length
export const MAX_SELECTED_ANSWER_REFERENCE_LENGTH = 120;
