import { Flex, Heading } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { InteractionSelector } from '../../../interaction/components/InteractionSelector';
import { IconBox } from '../../../commons/components/IconBox';
import { StaticContentSelector } from '../../../staticContent/components/StaticContentSelector';
import { VisualizationSelector } from '../../../visualization/components/VisualizationSelector';
import { translate } from '../../../../translate';

interface Props {}

export const EmptySlide: React.FC<Props> = () => {
	const [selectedElementType, setSelectedElementType] = useState<ElementTypeT | undefined>();

	const handleElementSelectorClick = (elementType: ElementTypeT) => () => {
		setSelectedElementType(elementType);
	};

	const resetSelectedElementType = () => {
		setSelectedElementType(undefined);
	};

	return (
		<Flex flexDirection='column' justifyContent='center' alignItems='center'>
			{!selectedElementType ? (
				<Flex flexDirection='column'>
					<Heading size='sm' textAlign='center'>
						{translate('What type of slide do you want to create?')}
					</Heading>
					<Flex flexDirection={['column', null, 'row']} gap={8} alignItems='center'>
						<IconBox
							iconImageSrc='/assets/brand/icons/ASK.svg'
							label={translate('Interaction')}
							description={translate('Inquire and get the information you need')}
							onClick={handleElementSelectorClick(ElementTypeT.INTERACTION)}
						/>
						<IconBox
							iconImageSrc='/assets/brand/icons/VIEW.svg'
							label={translate('Visualization')}
							description={translate('Bring your data to life with visualizations')}
							onClick={handleElementSelectorClick(ElementTypeT.VISUALIZATION)}
						/>
						<StaticContentSelector />
					</Flex>
				</Flex>
			) : null}

			{selectedElementType === ElementTypeT.INTERACTION ? <InteractionSelector onGoBack={resetSelectedElementType} /> : null}

			{selectedElementType === ElementTypeT.VISUALIZATION ? <VisualizationSelector onGoBack={resetSelectedElementType} /> : null}
		</Flex>
	);
};
