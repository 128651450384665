import { useQuery } from '@tanstack/react-query';
import { getThemeById } from '../service/ThemingService.ts';
import { ThemeT } from '../model/ThemeT.ts';

export const themeKey = (themeId: ThemeT['id']) => ['theme', themeId];

export const useTheme = (themeId?: ThemeT['id']) => {
	return useQuery({
		queryKey: themeKey(themeId ?? -1),
		queryFn: () => getThemeById(themeId!),
		enabled: !!themeId,
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	});
};
