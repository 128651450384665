import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Flex,
	Icon,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Table,
	TableContainer,
	TableProps,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { PresentationListT } from '../../../../../../types/Presentation.ts';
import { AiOutlineCopy, AiOutlineDelete, AiOutlineEdit, AiOutlineExport } from 'react-icons/ai';
import { deletePresentation, duplicatePresentation } from '../../../../service/PresentationService.ts';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { openPresentationInClientApp } from '../../../../service/LinkService.ts';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { translate } from '../../../../../../translate';
import { useMutation } from '@tanstack/react-query';

interface Props {
	presentations: PresentationListT[];
	onNewPage: (page: string) => void;
	onAfterDeletion: (presentation: PresentationListT) => void;
}

export const PresentationListTable: React.FC<Props & TableProps> = ({ presentations, onNewPage, onAfterDeletion }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const toast = useToast();
	const { mutate } = useMutation({
		mutationFn: (presentation: PresentationListT) => deletePresentation(presentation.id),
		onSuccess: (_, variables) => {
			onAfterDeletion(variables);
			onClose();
		},
		onError: (_, variables) => {
			toast({
				title: `Error when deleting presentation ${variables.title}. Try again later`,
				status: 'error',
				duration: 4000,
				isClosable: true,
				position: 'top',
			});
		},
	});
	const [presentationToDelete, setPresentationToDelete] = useState<PresentationListT>();
	const containerRef = useRef<HTMLDivElement | null>(null);
	const observerRef = useRef<IntersectionObserver | null>(null);

	useEffect(() => {
		// Create an intersection observer to detect when the last element is visible
		observerRef.current = new IntersectionObserver(
			(entries) => {
				console.log({ entry: entries[0] });
				if (entries[0].isIntersecting) {
					const lastPresentation = presentations[presentations.length - 1];
					if (lastPresentation) {
						onNewPage(lastPresentation.lastModifiedDate);
					}
				}
			},
			{ threshold: 0.01 }
		);

		// Observe the last row in the list
		const lastRow = containerRef.current?.querySelector('tbody > tr:last-child');
		if (lastRow) {
			observerRef.current?.observe(lastRow);
		}

		return () => {
			if (observerRef.current && lastRow) {
				observerRef.current?.unobserve(lastRow);
			}
		};
	}, [presentations]);

	const handleOnDelete = (presentationId: string) => () => {
		const selectedPresentation = find(presentations, { id: presentationId });
		setPresentationToDelete(selectedPresentation);
		onOpen();
	};

	const handleDelete = async () => {
		if (presentationToDelete) {
			mutate(presentationToDelete);
		}
	};

	const duplicate = (presentationId: string) => async () => {
		const newPresentation = await duplicatePresentation(presentationId);
		navigate(`/presentations/${newPresentation.presentationId}`);
	};

	return (
		<>
			{!isEmpty(presentations) ? (
				<>
					<TableContainer
						ref={containerRef}
						width='full'
						maxH='70vh'
						overflowY='auto'
						sx={{
							'::-webkit-scrollbar': {
								display: 'none',
							},
						}}
					>
						<Table>
							<Thead position='sticky' top={0} zIndex='docked'>
								<Tr>
									<Th>{translate('Title')}</Th>
									<Th>{translate('Last modified')}</Th>
									<Th>{translate('Actions')}</Th>
								</Tr>
							</Thead>
							<Tbody>
								{presentations.map((presentation) => (
									<Tr key={presentation.id}>
										<Td>
											<Link as={RouterLink} to={presentation.id}>
												<Text fontSize='md'>{presentation.title}</Text>
											</Link>
										</Td>
										<Td>
											<Link as={RouterLink} to={presentation.id}>
												<Text fontSize='md'>{new Date(presentation.lastModifiedDate).toLocaleString()}</Text>
											</Link>
										</Td>
										<Td>
											<Menu variant='simple'>
												<MenuButton>
													<Icon as={BiDotsHorizontalRounded} fontSize='xl' />
												</MenuButton>
												<MenuList fontSize={18}>
													<MenuItem icon={<AiOutlineExport />} onClick={() => openPresentationInClientApp(presentation.id)}>
														{translate('Play presentation')}
													</MenuItem>
													<MenuItem as={RouterLink} to={presentation.id} icon={<AiOutlineEdit />}>
														{translate('Edit')}
													</MenuItem>
													<MenuItem icon={<AiOutlineCopy />} onClick={duplicate(presentation.id)}>
														{translate('Duplicate')}
													</MenuItem>
													<MenuItem onClick={handleOnDelete(presentation.id)} icon={<AiOutlineDelete />} color='red'>
														{translate('Delete')}
													</MenuItem>
												</MenuList>
											</Menu>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
					<Modal isOpen={isOpen} size='xl' isCentered onClose={onClose}>
						<ModalOverlay />
						<ModalContent p={8}>
							<ModalCloseButton />
							<ModalHeader>
								<Text fontSize='2xl' textAlign='center'>
									Deleting: {presentationToDelete?.title}
								</Text>
							</ModalHeader>
							<ModalBody m={0} p={0} textAlign='center' as={Flex} justifyContent='center' alignItems='center'>
								<Text m={0} pt={0} fontSize='lg'>
									{translate('Are you sure?')}
								</Text>
							</ModalBody>
							<ModalFooter my={2} justifyContent='space-around'>
								<Button onClick={handleDelete} variant='submit'>
									{translate('Yes')}
								</Button>
								<Button onClick={onClose} variant='submit'>
									{translate('No')}
								</Button>
							</ModalFooter>
						</ModalContent>
					</Modal>
				</>
			) : null}
		</>
	);
};
