import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import theme from './theme/theme';
import './index.css';
import { PresentationListPage } from './pages/PresentationListPage';
import { PresentationPage } from './pages/PresentationPage';
import { HomePage } from './pages/HomePage';
import { AuthenticationGuard } from './components/AuthenticationGuard';
import { firebaseAuth } from './modules/auth/core/Firebase.ts';
import { FirebaseAuthProvider } from './modules/auth/FirebaseAuthProvider';
import { LoginPage } from './modules/auth/pages/LoginPage';
import { ForgotPasswordPage } from './modules/auth/pages/ForgotPasswordPage';
import { EntryManagementPage } from './pages/EntryManagementPage';

// Create a react query client
const queryClient = new QueryClient();

function App() {
	return (
		<>
			<ColorModeScript initialColorMode={theme.config.initialColorMode} />
			<QueryClientProvider client={queryClient}>
				<ChakraProvider theme={theme}>
					<BrowserRouter>
						<FirebaseAuthProvider auth={firebaseAuth}>
							<Routes>
								<Route path='/' element={<HomePage />} />
								<Route path='/login' element={<LoginPage />} />
								<Route path='/forgot-password' element={<ForgotPasswordPage />} />
								<Route
									path='/presentations'
									element={
										<AuthenticationGuard>
											<PresentationListPage />
										</AuthenticationGuard>
									}
								/>
								<Route
									path='/presentations/:presentationId'
									element={
										<AuthenticationGuard>
											<PresentationPage />
										</AuthenticationGuard>
									}
								/>
								<Route
									path='presentations/:presentationId/slides/:slideId/new?'
									element={
										<AuthenticationGuard>
											<PresentationPage />
										</AuthenticationGuard>
									}
								/>
								<Route
									path='presentations/:presentationId/interactions/:interactionId/manage-entries'
									element={
										<AuthenticationGuard>
											<EntryManagementPage />
										</AuthenticationGuard>
									}
								/>
							</Routes>
						</FirebaseAuthProvider>
					</BrowserRouter>
				</ChakraProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</>
	);
}

export default App;
