import React, { useEffect } from 'react';
import {
	Box,
	Flex,
	FormControl,
	FormLabel,
	Icon,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Switch,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useToken,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';

import debounce from 'lodash/debounce';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { IoSettingsSharp } from 'react-icons/io5';
import { MdAdd, MdClose } from 'react-icons/md';
import { InteractionT, ValuationConfigurationStatementEntry, ValuationConfigurationT } from '../../../../types/Interaction.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { useUpdateInteraction } from '../../hooks/useInteraction.ts';
import { saveValuationInteraction } from '../../service/InteractionService.ts';
import {
	AUTO_SAVE_DEBOUNCE_TIME,
	INPUT_DESCRIPTION_MAX_LENGHT,
	INPUT_TITLE_MAX_LENGHT,
	MULTIPLE_CHOICE_OPTIONS_MAX_COUNT,
	MULTIPLE_CHOICE_OPTIONS_MAX_LENGTH,
	MULTIPLE_CHOICE_OPTIONS_MIN_COUNT,
	MULTIPLE_CHOICE_OPTIONS_MIN_LENGTH,
} from '../../../../types/Constants.ts';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { InputTitle } from '../../../commons/components/InputTitle';
import { InputSubtitle } from '../../../commons/components/InputSubtitle';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { ColorInput } from '../../../commons/components/ColorInput';
import { translate } from '../../../../translate';
import { useThemeForPresentation } from '../../../theming/hooks/useThemeForPresentation.ts';
import { getRandomThemeColor } from '../../../theming/service/ThemeColorService.ts';

interface Props {
	presentationId: string;
	interaction: InteractionT;
}

export const ValuationInteraction: React.FC<Props> = ({ presentationId, interaction }) => {
	const conf = interaction.configuration as ValuationConfigurationT;
	const [brand] = useToken('colors', ['brand']);
	const { data: theme } = useThemeForPresentation(presentationId);
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateInteraction = useUpdateInteraction(presentationId, interaction.id, (data: ValuationConfigurationT) => saveValuationInteraction(presentationId, interaction.id, data));

	const addEmptyCategory = () => {
		const color = theme ? getRandomThemeColor(theme) : brand;
		const category: ValuationConfigurationStatementEntry = {
			statement: '',
			color,
		};
		append(category);
	};

	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors },
	} = useForm<ValuationConfigurationT>({
		defaultValues: {
			title: interaction.title,
			description: interaction.description,
			statements: conf.statements,
			minValue: conf.minValue,
			maxValue: conf.maxValue,
			required: interaction.required,
		},
	});

	const { fields, append, remove } = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: 'statements',
		rules: {
			minLength: MULTIPLE_CHOICE_OPTIONS_MIN_COUNT,
			maxLength: MULTIPLE_CHOICE_OPTIONS_MAX_COUNT,
			required: true,
		},
	});

	const onSubmit: SubmitHandler<ValuationConfigurationT> = async (data) => {
		updateInteraction.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch, handleSubmit]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={interaction} elementType={ElementTypeT.INTERACTION} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							{' '}
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
						{/* <Tab fontSize={20}>
              <Icon as={BsFillPaletteFill} pr={2} />
              Design
            </Tab> */}
					</TabList>
					<TabPanels>
						<TabPanel>
							<InputTitle
								title={watch('title')}
								{...register('title', {
									maxLength: INPUT_TITLE_MAX_LENGHT,
									required: true,
								})}
							/>
							<Flex flexDirection='column'>
								<InputSubtitle
									title={watch('description')}
									{...register('description', {
										maxLength: INPUT_DESCRIPTION_MAX_LENGHT,
									})}
								/>
								<FormControl mt={8}>
									<Text size='lg'>Statements</Text>
									{fields.map((field, index) => (
										<Flex w='full' flexDirection='row' key={field.id} alignItems='center' gap={2}>
											<Controller
												name={`statements.${index}.color`}
												control={control}
												rules={{ required: false }}
												render={({ field }) => <ColorInput value={field.value} onChange={field.onChange} />}
											/>
											<Flex w='100%'>
												<FormControl isInvalid={!!errors.statements?.[index]}>
													<InputGroup size='lg' key={field.id} my={4} justifyContent='center' alignContent='center' flexDirection='column'>
														<Input
															id={field.id}
															size='lg'
															placeholder=' '
															data-peer
															minLength={MULTIPLE_CHOICE_OPTIONS_MIN_LENGTH}
															maxLength={MULTIPLE_CHOICE_OPTIONS_MAX_LENGTH}
															required
															autoComplete='off'
															{...register(`statements.${index}.statement` as const, {
																required: true,
																minLength: MULTIPLE_CHOICE_OPTIONS_MIN_LENGTH,
																maxLength: MULTIPLE_CHOICE_OPTIONS_MAX_LENGTH,
															})}
														/>
														<InputRightElement width='0.5rem' px={6} onClick={() => remove(index)}>
															<IconButton rounded='50' aria-label='Delete' size='xs' icon={<Icon color='brand' as={MdClose} />} />
														</InputRightElement>
													</InputGroup>
												</FormControl>
											</Flex>
										</Flex>
									))}
									{fields.length < MULTIPLE_CHOICE_OPTIONS_MAX_COUNT ? (
										<Flex justifyContent='flex-end'>
											<IconButton rounded='50' bg='brand' aria-label='' onClick={addEmptyCategory} size='lg' w='8' icon={<Icon as={MdAdd} />} />
										</Flex>
									) : null}
								</FormControl>
							</Flex>
						</TabPanel>
						<TabPanel>
							<FormControl display='flex' alignItems='center'>
								<Switch size='lg' id='required' {...register('required')} />
								<FormLabel htmlFor='required' fontSize='lg' mx={2}>
									{translate('Answer Required')}
								</FormLabel>
							</FormControl>
							<FormControl mt={4}>
								<Text size='lg'>Accepted values</Text>
								<Flex alignItems='baseline'>
									<Flex pr={2}>
										<Text>MIN</Text>
									</Flex>
									<Flex>
										<Input
											w={24}
											id='minValue'
											type='number'
											placeholder='10'
											min={0}
											required
											autoComplete='off'
											{...register('minValue', {
												required: true,
												min: 0,
											})}
										/>
									</Flex>
									<Flex pr={2}>
										<Text>MAX</Text>
									</Flex>
									<Input
										w={24}
										type='number'
										placeholder='30'
										min={1}
										required
										autoComplete='off'
										{...register('maxValue', {
											required: true,
											min: 1,
										})}
									/>
								</Flex>
							</FormControl>
							<Box mt={8}>
								<SlideSettingsInput presentationId={presentationId} slide={interaction.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
