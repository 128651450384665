import { Flex, IconButton, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useAuth } from '../../../auth/FirebaseAuthProvider/FirebaseAuthProvider.tsx';
import { MdLogout } from 'react-icons/md';
import { useRemovePresentationList } from '../../hooks/usePresentationList.ts';
import { QuestioryLogo } from '../../../../components/QuestioryLogo';

interface Props {}

export const DefaultMenu: React.FC<Props> = () => {
	const { currentUser, logout } = useAuth();
	const removePresentationList = useRemovePresentationList();
	const handleLogout = async () => {
		removePresentationList();
		await logout();
	};

	return (
		<Flex as='nav' w='100%' boxShadow={useColorModeValue('xxs', 'xxs-dark')} justifyContent='space-between' px={16} pt={8} alignContent='center'>
			<Flex alignSelf='center'>
				<QuestioryLogo height='9' />
			</Flex>
			<Flex flexDir='row' justifyContent='flex-end' alignItems='center'>
				<Text>{currentUser?.email}</Text>
				<IconButton aria-label='logout' icon={<MdLogout />} color='brand' m={0} onClick={handleLogout} />
			</Flex>
		</Flex>
	);
};
