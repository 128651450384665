import React, { useState } from 'react';
import { NewInteraction } from '../../../../../interaction/components/InteractionSelector/_/NewInteraction';
import { NewVisualization } from './_/NewVisualization';
import { NewVisualizationValues } from './_/NewVisualization/NewVisualization.tsx';
import { NewInteractionValues } from '../../../../../interaction/components/InteractionSelector/_/NewInteraction/NewInteraction.tsx';
import { createVisualization } from '../../../../../presentation/service/ElementService.ts';
import { usePresentationId } from '../../../../../presentation/hooks/usePresentationId.ts';
import { useInvalidatePresentationQuery } from '../../../../../presentation/hooks/usePresentation.ts';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../../../../commons/components/Loading';
import { useVisualizationCompatibilityMap } from '../../../../hooks/useVisualizationCompatibilityMap.ts';
import { VisualizationTypeT } from '../../../../../../types/Visualization.ts';
import { translate } from '../../../../../../translate';

const allVisualizations: VisualizationTypeT[] = [
	VisualizationTypeT.WORD_CLOUD,
	VisualizationTypeT.WHEEL,
	VisualizationTypeT.BASIC_CARDS,
	VisualizationTypeT.BASIC_FLIP_CARDS,
	VisualizationTypeT.BAR_CHART,
	VisualizationTypeT.PIE_CHART,
	VisualizationTypeT.GAUGE,
	VisualizationTypeT.RADAR,
	VisualizationTypeT.NODES,
	VisualizationTypeT.BLOCKS,
	VisualizationTypeT.PICTOGRAM,
	VisualizationTypeT.COMIC,
	VisualizationTypeT.ROTATING_WORDS,
	VisualizationTypeT.SIMPLE_MATH,
];

interface Props {
	onGoBack: () => void;
}

export const NewDataVisualization: React.FC<Props> = ({ onGoBack }) => {
	const presentationId = usePresentationId();
	const invalidatePresentationQuery = useInvalidatePresentationQuery(presentationId);
	const navigate = useNavigate();
	const { isPending, data: visualizationCompatilityMap } = useVisualizationCompatibilityMap();
	const [visualizationValues, setVisualizationValues] = useState<NewVisualizationValues | undefined>();
	const [interactionValues, setInteractionValues] = useState<NewInteractionValues | undefined>();
	const [isLoading, setIsLoading] = useState(false);

	const handleOnGoBack = () => {
		setVisualizationValues(undefined);
		setInteractionValues(undefined);
	};

	const handleSubmit = async (values: NewInteractionValues) => {
		setInteractionValues(values);
		if (visualizationValues && values) {
			setIsLoading(true);
			const newSlide = await createVisualization(presentationId, {
				title: values.title,
				type: visualizationValues.type,
				newInteraction: {
					title: values.title,
					type: values.type,
				},
			});
			invalidatePresentationQuery();
			navigate(`/presentations/${presentationId}/slides/${newSlide.slideId}/new`);
		}
	};

	return (
		<>
			{!visualizationValues ? (
				<>
					<NewVisualization supportedVisualizations={allVisualizations} onSelectedVisualization={setVisualizationValues} onGoBack={onGoBack} showTitle />
				</>
			) : null}
			{isPending ? <Loading /> : null}
			{!isPending && visualizationValues && visualizationCompatilityMap && !interactionValues ? (
				<NewInteraction
					title={translate('How do you want to capture the data for this visualization?')}
					supportedInteractions={visualizationCompatilityMap[visualizationValues.type]}
					onSelectedInteraction={handleSubmit}
					onGoBack={handleOnGoBack}
				/>
			) : null}
			{isLoading ? <Loading /> : null}
		</>
	);
};
