import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getPresentationList } from '../service/PresentationService.ts';

const key = (search: string, page: Date) => ['presentation-list', search, page];

export const usePresentationList = (search: string, page: Date) => {
	return useQuery({
		queryKey: key(search, page),
		queryFn: () => getPresentationList(search, page),
		refetchOnWindowFocus: false,
		staleTime: 30000,
	});
};

export const useRemovePresentationList = () => {
	const queryClient = useQueryClient();
	return () =>
		queryClient.removeQueries({
			queryKey: ['presentation-list'],
			exact: false,
		});
};
