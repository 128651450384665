import React from 'react';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { MdSettings } from 'react-icons/md';
import { BasicPresentationSettings } from './_/BasicPresentationSettings';
import { translate } from '../../../../translate';
import { BasicPresentationT } from '../../../../types/Presentation.ts';

interface Props {
	presentationId: BasicPresentationT['id'];
}

export const PresentationSettings: React.FC<Props> = ({ presentationId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Button rightIcon={<MdSettings />} variant='ghost' size='lg' onClick={onOpen} px={5}>
				{translate('Settings')}
			</Button>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{translate('Presentation settings')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<BasicPresentationSettings presentationId={presentationId} />
					</ModalBody>

					<ModalFooter>
						<Button variant='outline' mr={3} onClick={onClose}>
							{translate('Close')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
